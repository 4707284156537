import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colors, fonts, fontSizes } from "../../../util/Style-Guide";
import { useNavigate } from "react-router-dom";
import IndicatorMsme from "../StepIndicator/IndicatorMsme";

const Page6 = ({ formData, setFormData, nextStep, prevStep }) => {
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate("/");
  };

  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};

    if (!formData.loanApplication) {
      newErrors.loanApplication = "This field is required.";
    }

    if (formData.loanApplication === "yes" && !formData.ifYesWasItSuccessful) {
      newErrors.ifYesWasItSuccessful = "This field is required.";
    }
    if (!formData.financialServiceInUse) {
      newErrors.financialServiceInUse = "This field is required.";
    }
    if (!formData.financialInterest) {
      newErrors.financialInterest = "This field is required.";
    }
    if (!formData.financeUseCase) {
      newErrors.financeUseCase = "This field is required.";
    }

    if (
      formData.financeUseCase === "Others" &&
      !formData.IfOthersPleaseSpecifyUse
    ) {
      newErrors.IfOthersPleaseSpecifyUse = "This field is required.";
    }
    if (!formData.reinvest) {
      newErrors.reinvest = "This field is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextStep = () => {
    if (validateForm()) {
      nextStep();
    }
  };

  return (
    <FullScreenBackground>
      <CenteredContentWrapper>
        <ContentWrapper>
          <Content>
            <Header>Business Evaluation</Header>
            <IndicatorMsme currentStep={6} totalSteps={10} />
            <Ptag>
              Let’s get to know you enter your accurate business information for
              the program.
            </Ptag>

            <ButtonWrapper>
              <ApplyButton onClick={navigateToHome}>Back to home</ApplyButton>
            </ButtonWrapper>
          </Content>

          <StyledForm>
            <StyledChild>
              <StyledLabel htmlFor="loanApplication">
                Have you ever applied for a loan or credit for your business?
              </StyledLabel>
              <StyledSelect
                id="loanApplication"
                name="loanApplication"
                defaultValue={formData.loanApplication}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    loanApplication: e.target.value,
                  })
                }
                required
              >
                <option value="">Select an option</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </StyledSelect>
              {errors.loanApplication && (
                <ErrorMessage>{errors.loanApplication}</ErrorMessage>
              )}
            </StyledChild>

            {formData.loanApplication === "yes" && (
              <StyledChild>
                <StyledLabel htmlFor="ifYesWasItSuccessful">
                  If yes, were you successful in securing the loan?
                </StyledLabel>

                <StyledSelect
                  id="ifYesWasItSuccessful"
                  name="ifYesWasItSuccessful"
                  defaultValue={formData.ifYesWasItSuccessful}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      ifYesWasItSuccessful: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select an option</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </StyledSelect>
                {errors.ifYesWasItSuccessful && (
                  <ErrorMessage>{errors.ifYesWasItSuccessful}</ErrorMessage>
                )}
              </StyledChild>
            )}
            <StyledChildDiv>
              <StyledChild>
                <StyledLabel htmlFor="financialServiceInUse">
                  What type of financial services are you currently using?
                </StyledLabel>

                <StyledSelect
                  id="financialServiceInUse"
                  name="financialServiceInUse"
                  defaultValue={formData.financialServiceInUse}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      financialServiceInUse: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select an option</option>
                  <option value="	Personal Savings Account">
                    {" "}
                    Personal Savings Account
                  </option>
                  <option value="	Current Account"> Current Account</option>
                  <option value="	Corporate Account"> Corporate Account</option>
                  <option value="All of the Above">All of the Above</option>
                  <option value="None of the Above">None of the Above</option>
                </StyledSelect>
                {errors.financialServiceInUse && (
                  <ErrorMessage>{errors.financialServiceInUse}</ErrorMessage>
                )}
              </StyledChild>
              <StyledChild>
                <StyledLabel htmlFor="financialInterest">
                  What type of financing are you interested in accessing?
                </StyledLabel>

                <StyledSelect
                  id="financialInterest"
                  name="financialInterest"
                  defaultValue={formData.financialInterest}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      financialInterest: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select an option</option>
                  <option value="Bank_Loan"> Bank Loan</option>
                  <option value="Equity_Investment"> Equity Investment</option>
                  <option value="Grants"> Grants</option>
                  <option value="Others"> Others</option>
                </StyledSelect>
                {errors.financialInterest && (
                  <ErrorMessage>{errors.financialInterest}</ErrorMessage>
                )}
              </StyledChild>
            </StyledChildDiv>

            <StyledChildDiv>
              <StyledChild>
                <StyledLabel htmlFor="financeUseCase">
                  What do you intend to use the financing for?
                </StyledLabel>

                <StyledSelect
                  id="financeUseCase"
                  name="financeUseCase"
                  defaultValue={formData.financeUseCase}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      financeUseCase: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select an option</option>
                  <option value="working_capital"> Working Capital</option>
                  <option value="Asset_Purchase"> Asset Purchase</option>
                  <option value="Business_Expansion">
                    {" "}
                    Business Expansion
                  </option>
                  <option value="Others"> Others</option>
                </StyledSelect>
                {errors.financeUseCase && (
                  <ErrorMessage>{errors.financeUseCase}</ErrorMessage>
                )}
              </StyledChild>

              {formData.financeUseCase === "Others" && (
                <StyledChild>
                  <StyledLabel htmlFor="IfOthersPleaseSpecifyUse">
                    If others, please specify
                  </StyledLabel>
                  <StyledInput
                    type="text"
                    id="IfOthersPleaseSpecifyUse"
                    name="IfOthersPleaseSpecifyUse"
                    placeholder="If others, please specify"
                    value={formData.IfOthersPleaseSpecifyUse}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        IfOthersPleaseSpecifyUse: e.target.value,
                      })
                    }
                  />

                  {errors.IfOthersPleaseSpecifyUse && (
                    <ErrorMessage>
                      {errors.IfOthersPleaseSpecifyUse}
                    </ErrorMessage>
                  )}
                </StyledChild>
              )}
            </StyledChildDiv>
            <StyledChildDiv>
              <StyledChild>
                <StyledLabel htmlFor="reinvest">
                  What percentage of your business revenue is reinvested into
                  the business?
                </StyledLabel>

                <StyledSelect
                  id="reinvest"
                  name="reinvest"
                  defaultValue={formData.reinvest}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      reinvest: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select an option</option>
                  <option value="0-10%"> 0-10%</option>
                  <option value="11-25%"> 11-25%</option>
                  <option value="26-50%"> 26-50%</option>
                  <option value="More_than_50%"> More than 50%</option>
                </StyledSelect>
                {errors.reinvest && (
                  <ErrorMessage>{errors.reinvest}</ErrorMessage>
                )}
              </StyledChild>
            </StyledChildDiv>

            <ButtonWrapper>
              <StyledButton onClick={prevStep}> Previous</StyledButton>
              <StyledButton2 onClick={handleNextStep}> Next</StyledButton2>
            </ButtonWrapper>
          </StyledForm>
        </ContentWrapper>
      </CenteredContentWrapper>
    </FullScreenBackground>
  );
};

export default Page6;

const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

const FullScreenBackground = styled.div`
  background-image: url("/Formbackground.png");
  background-size: cover;
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    padding: 2rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    padding: 0rem !important;
  }
`;

const CenteredContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  padding: 10px;
  @media only screen and (max-width: 420px) {
    flex-direction: column !important;
    padding: 10px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column !important;
    padding: 10px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    min-height: 100% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    min-height: 100% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    /* min-height: 100% !important; */
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.GRAY};
  max-width: 1200px !important;
  min-width: 1200px !important;
  margin: 0 auto;
  border-radius: 40px;
  padding: 3rem !important;
  min-height: 80vh !important;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    padding: 1rem !important;
    border-radius: 24px;
    max-width: 80% !important;
    min-width: 80% !important;
    margin: 0 !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column !important;
    padding: 1rem !important;
    max-width: 100% !important;
    min-width: 80% !important;
    border-radius: 24px;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    /* padding: 3rem 0 !important; */
    max-width: 100% !important;
    min-width: 80% !important;
    border-radius: 24px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    /* padding: 3rem 0 !important; */
  }
`;

const Content = styled.div`
  /* padding: 20px; */
  width: 40% !important;

  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Header = styled.h1`
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes[48]};
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -0.04em;
  text-align: left;
  color: ${colors.DARK_BROWN};
  margin: 0;
  padding: 0;
  width: 50%;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
    padding-bottom: 1rem !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const Ptag = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 500;
  line-height: 25.6px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${colors.DEEP_BROWN};
  width: 70% !important;
  /* margin: 0;
padding: 0; */
`;

const ApplyButton = styled.button`
  background: ${colors.WHITE};
  gap: 10px;
  padding: 1.2rem 3rem;
  border-radius: 10px;
  border: 0px solid ${colors.WHITE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 600;
  cursor: pointer;
`;

const StyledForm = styled.form`
  width: 40% !important;
  @media only screen and (max-width: 420px) {
    width: 100% !important;
    margin-top: 1rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
    margin-top: 1rem !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    width: 50% !important;
  }
`;

const StyledChild = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  @media only screen and (max-width: 420px) {
    width: 100%;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 80%;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    width: 60%;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    /* width: 100%; */
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    width: 30%;
  }
`;
const StyledChildDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    gap: 0;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
    gap: 0;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    flex-direction: column !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    flex-direction: column !important;
    gap: 0;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const StyledLabel = styled.label`
  color: ${colors.BLACK};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 150% */
`;
const StyledInput = styled.input`
  border-radius: var(--Radius-radius-sm, 8px);
  border: 1px solid ${colors.LIGHT_GRAY};
  background: ${colors.DEEP_GRAY};
  color: ${colors.DEEPER_GRAY};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding: 4px 12px 4px 12px !important;
  margin-top: 0.5rem;

  &:focus {
    outline: ${`1px solid ${colors.PINK}`};
    font-family: ${fonts.FONT_FAMILY};
  }

  @media only screen and (max-width: 420px) {
    height: 3vh !important;
    font-size: 12px !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    height: 5vh !important;
    font-size: 12px !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    height: 5vh;
  }
`;
const StyledSelect = styled.select`
  border-radius: var(--Radius-radius-sm, 8px);
  border: 1px solid ${colors.LIGHT_GRAY};
  background: ${colors.DEEP_GRAY};
  color: ${colors.DEEPER_GRAY};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding: 4px 12px 4px 12px !important;
  margin-top: 0.5rem;
  height: 6vh !important;

  &:focus {
    outline: ${`1px solid ${colors.PINK}`};
    font-family: ${fonts.FONT_FAMILY};
  }

  @media only screen and (max-width: 420px) {
    height: 5vh !important;
    font-size: 12px !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    height: 5vh !important;
    font-size: 12px !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    height: 5vh;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 2rem !important;

  @media only screen and (max-width: 420px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    flex-wrap: wrap !important;
  }
`;

const StyledButton = styled.button`
  padding: 1.2rem 3rem;
  background: ${colors.WHITE};
  border-radius: 12px;
  color: ${colors.DARK_GREY};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 600;
  line-height: 16px;
  width: 100% !important;
  border: 0px solid ${colors.ORANGE};
  margin-top: 0rem;
  cursor: pointer;
  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const StyledButton2 = styled.button`
  padding: 1.2rem 3rem;
  background: ${colors.ORANGE};
  border-radius: 12px;
  color: ${colors.WHITE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 600;
  line-height: 16px;
  width: 100% !important;
  border: 0px solid ${colors.ORANGE};
  margin-top: 0rem;
  cursor: pointer;
  &:hover {
    background: ${colors.DARK_BROWN} !important;
  }
  &:active {
    background: ${colors.ORANGE_LIGHT};
  }

  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
