/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import React from "react";
import styled from "styled-components";
import { colors, fonts, fontSizes } from "../../util/Style-Guide";
import { useNavigate } from "react-router-dom";
import Index from "../Navbar/Index";

const Page1 = () => {
  const navigate = useNavigate();
  const navigateToSignup = () => {
    navigate("/registration-flow");
  };

  return (
    <Maindiv>
      <Index />
      {/* <BoxImage>
        <ImageCube src="/Box.png" alt="Box" />
      </BoxImage> */}
      <Parentdiv>
        <Childdiv>
          <LadyHatParent1>
            <LadyHat src="/BlackWoman2.png" alt="LadyInHat" />
          </LadyHatParent1>
          <Sisterdiv>
            <Header>Ready to make your mark in fashion</Header>
            <Ptag>
              Your journey to success begins today, transform your creativity
              into a thriving career or business.
            </Ptag>
            <ButtonWrapper>
              <ApplyButton onClick={navigateToSignup}>Apply now</ApplyButton>
              <PlayvideoButton>
                <PlayVideoTag
                  href="https://www.youtube.com/@FashionFutureProgram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/Play.svg" alt="Play Video" />
                  Play video
                </PlayVideoTag>
              </PlayvideoButton>
            </ButtonWrapper>
            <Ptag>Application closes Thursday, 31st October, 2024.</Ptag>
            <MasterCardFoundation>
              <PtagMaster>In partnership with</PtagMaster>

              <img src="/MasterCard.svg" alt="MasterCard Foundation" />
            </MasterCardFoundation>
          </Sisterdiv>
          <LadyHatParent2>
            <LadyHat src="/BlackWoman2.png" alt="LadyInHat" />
          </LadyHatParent2>
        </Childdiv>
      </Parentdiv>
    </Maindiv>
  );
};
export default Page1;

const Maindiv = styled.div`
  background: linear-gradient(252.87deg, #f7ece0 28.09%, #fbede0 82.77%),
    url("/Hero.svg");

  @media only screen and (max-width: 420px) {
    max-width: 100%;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    max-width: 100%;
  }

  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    height: 100vh !important;
  }
`;

const BoxImage = styled.div`
  text-align: right;
  position: relative;

  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
  }
`;

const ImageCube = styled.img`
  height: 20vh;
  @media only screen and (max-width: 420px) {
    height: 10vh;
  }
`;

const Parentdiv = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 20px 0 20px;

  @media only screen and (max-width: 420px) {
    padding-top: 3rem;
    padding-bottom: 1rem;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    padding-top: 3rem;
    padding-bottom: 1rem;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    padding-top: 5rem;
    padding-bottom: 3rem;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    padding-top: 5rem;
    /* padding-bottom: 3rem; */
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    padding-top: 5rem;
    /* padding-bottom: 3rem; */
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    padding-top: 5rem;
    /* padding-bottom: 5rem; */
  }
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
    padding-top: 5rem;
    /* padding-bottom: 5rem; */
  }
`;

const Childdiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    align-items: self-start !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    /* align-items: self-start !important; */
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Sisterdiv = styled.div`
  width: 50%;
  @media only screen and (max-width: 420px) {
    width: 80%;
    margin-top: 1rem;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 80%;
    margin-top: 1rem;
  }

  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    width: 40%;
  }
`;

const Header = styled.h1`
  font-family: ${fonts.FONT_FAMILY_HEADER};
  /* font-size: ${fontSizes[48]}; */
  font-size: ${fontSizes.smallDeskTop};
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -0.04em;
  text-align: left;
  color: ${colors.DARK_BROWN};
  margin: 0;
  padding: 0;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 30px;
    text-align: center;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 30px;
    text-align: center;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 30px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 30px;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes[48]};
    line-height: 60px;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes[48]};
    /* font-size: ${fontSizes.extraLarge}; */
    line-height: 60px;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Ptag = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 500;
  line-height: 25.6px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${colors.DEEP_BROWN};
  width: 70% !important;
  /* margin: 0;
padding: 0; */

  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraSmall};
    width: 100% !important;
    text-align: center;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraSmall};
    text-align: center;
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraSmall};
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 2rem !important;

  @media only screen and (max-width: 420px) {
    flex-direction: column;
    justify-content: center;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
    justify-content: center;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    flex-direction: column;
    justify-content: start !important;
    align-items: self-start !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const ApplyButton = styled.button`
  background: ${colors.ORANGE};
  padding: 15px 44px;
  border-radius: 12px;
  border: 0px solid ${colors.ORANGE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  color: ${colors.WHITE};
  font-size: ${fontSizes.medium};
  cursor: pointer;

  &:hover {
    background: ${colors.DARK_BROWN};
  }

  &:active {
    background: ${colors.ORANGE_LIGHT};
  }

  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraSmall};
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraSmall};
    width: 100% !important;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    width: 80% !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const PlayvideoButton = styled.button`
  background: ${colors.WHITE};
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 44px;
  border-radius: 12px;
  border: 0px solid ${colors.WHITE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  cursor: pointer;

  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraSmall};
    width: 100% !important;
    justify-content: center !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraSmall};
    width: 100% !important;
    justify-content: center !important;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    width: 80% !important;
    justify-content: center !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const PlayVideoTag = styled.a`
  text-decoration: none;
  color: ${colors.DEEP_BROWN};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
`

const MasterCardFoundation = styled.div`
  margin-top: 3rem;
  @media only screen and (max-width: 420px) {
    margin-top: 1rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    margin-top: 1rem !important;
  }
`;
const PtagMaster = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-weight: 500;
  line-height: 25.6px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${colors.DEEP_BROWN};
  margin: 0;
  padding: 0;
`;

const LadyHat = styled.img`
  height: 100vh;
  @media only screen and (max-width: 420px) {
    height: 30vh;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    height: 30vh;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    height: 40vh;
  }
  @media only screen and (min-width: 769px) and (max-width: 921px) {
    height: 65vh;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    height: 70vh;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    height: 85vh;
    width: 100% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
    height: 70vh;
  }
`;

const LadyHatParent1 = styled.div`
  display: none;
  @media only screen and (max-width: 420px) {
    display: unset !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    display: unset !important;
  }
`;
const LadyHatParent2 = styled.div`
  @media only screen and (max-width: 420px) {
    display: none !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    display: none !important;
  }
`;
