/* eslint-disable no-undef */
import React, { useState } from "react";
import styled from "styled-components";
import { colors, fonts, fontSizes } from "../../util/Style-Guide";
import Indicator from "./StepIndicator/Indicator";
import { useNavigate } from "react-router-dom";
import { statesInNigeria } from "./statesInNigeria";
import Select from "react-select";


const options = [
  { value: "no-one", label: "No one" },
  { value: "my-family", label: "My family" },
  { value: "my-children", label: "My children" },
  { value: "other-children", label: "Other children" },
  { value: "an-elderly-person", label: "An elderly person" },
  { value: "a-disabled-person", label: "A disabled person" },
];

const PersonalTwo = ({ nextStep, prevStep, formData, setFormData }) => {
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate("/");
  };

  const handleNext = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    if (validateForm()) {
      nextStep();
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.maritalStatus)
      newErrors.maritalStatus = "Marital status is required";
    if (!formData.residentialAddress)
      newErrors.residentialAddress = "Specific home address is required";
    if (!formData.state) newErrors.state = "State is required";
    if (!formData.livingSituation)
      newErrors.livingSituation = "Current living situation is required";
    if (!formData.mainpersonearningmoney)
      newErrors.mainpersonearningmoney = "Primary earner status is required";
    if (!formData.careTaker || formData.careTaker.length === 0)
      newErrors.careTaker = "Please select who you take care of";
    if (!formData.childrenbelowfive)
      newErrors.childrenbelowfive =
        "Please specify if you take care of children below 5 years";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSelectChange = (selectedOptions) => {
    setFormData({
      ...formData,
      careTaker: selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [],
    });
  };



  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "8px",
      border: `1px solid ${colors.LIGHT_GRAY}`,
      background: `${colors.DEEP_GRAY}`,
      color: `${colors.DEEPER_GRAY}`,
      fontFamily: `${fonts.FONT_FAMILY_BODY}`,
      fontSize: `${fontSizes.extraSmall}`,
      fontStyle: "normal",
      fontWeight: 400,
      marginTop: "0.5rem",
      width: "100%",
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0, 
      maxWidth: "95%", 
      overflowX: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px", 
      overflowY: "auto",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? `${colors.PRIMARY}` : `${colors.DEEP_GRAY}`,
      color: state.isSelected ? `${colors.WHITE}` : `${colors.DEEPER_GRAY}`,
      padding: "2px 2px 2px 12px", 
      fontFamily: `${fonts.FONT_FAMILY_BODY}`,
      fontSize: `${fontSizes.extraSmall}`,
      cursor: 'pointer',
    ':hover': {  
      backgroundColor: '#0056b3', 
      color: `${colors.WHITE}`,
    },
    }),
    multiValue: (provided) => ({
      ...provided,
    }),
    multiValueLabel: (provided) => ({
      ...provided,
    }),
    multiValueRemove: (provided) => ({
      ...provided,
    }),
  };

  const initialValues = {
    maritalStatus: formData.maritalStatus || "",
    residentialAddress: formData.residentialAddress || "",
    state: formData.state || "",
    livingSituation: formData.livingSituation || "",
    mainpersonearningmoney: formData.mainpersonearningmoney || "",
    careTaker: formData.careTaker || [],
    childrenbelowfive: formData.childrenbelowfive || "",
  };

  return (
    <FullScreenBackground>
      <CenteredContentWrapper>
        <ContentWrapper>
          <Content>
            <Header>Personal Information</Header>
            <Indicator currentStep={2} totalSteps={6} />
            <Ptag>
              Let’s get to know you enter your accurate personal information for
              the program.
            </Ptag>

            <ButtonWrapper>
              <ApplyButton onClick={navigateToHome}>Back to home</ApplyButton>
            </ButtonWrapper>
          </Content>

          <StyledForm>
            <StyledChild>
              <StyledLabel htmlFor="marital-status">Marital Status</StyledLabel>
              <StyledSelect
                id="marital-status"
                name="marital-status"
                defaultValue={formData.maritalStatus}
                onChange={(e) =>
                  setFormData({ ...formData, maritalStatus: e.target.value })
                }
                required
              >
                <option value="">Select an option</option>
                <option value="single">Single</option>
                <option value="married">Married</option>
                <option value="divorced">Divorced</option>
                <option value="single_parent">Single parent</option>
                <option value="widowed">Widowed</option>
              </StyledSelect>
              {errors.maritalStatus && (
                <ErrorMessage>{errors.maritalStatus}</ErrorMessage>
              )}
            </StyledChild>

            <StyledChildDiv>
              <StyledChild>
                <StyledLabel htmlFor="residential_address">
                  Specific Home Address
                </StyledLabel>
                <StyledInput
                  type="residential_address"
                  id="residential_address"
                  name="residential_address"
                  placeholder="Enter your address"
                  defaultValue={formData.residentialAddress}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      residentialAddress: e.target.value,
                    })
                  }
                  required
                />
                {errors.residentialAddress && (
                  <ErrorMessage>{errors.residentialAddress}</ErrorMessage>
                )}
              </StyledChild>
              <StyledChild>
                <StyledLabel htmlFor="state">State</StyledLabel>
                <StyledSelect
                  id="state"
                  name="state"
                  type="text"
                  defaultValue={formData.state}
                  onChange={(e) =>
                    setFormData({ ...formData, state: e.target.value })
                  }
                  required
                >
                  <option value="">Select an option</option>
                  {statesInNigeria.map((state) => (
                    <option key={state.value} value={state.value}>
                      {state.name}
                    </option>
                  ))}  
                </StyledSelect>
                {errors.state && <ErrorMessage>{errors.state}</ErrorMessage>}
              </StyledChild>
            </StyledChildDiv>
            <StyledChildDiv>
              <StyledChild>
                <StyledLabel htmlFor="living-situation">
                  Current living situation
                </StyledLabel>
                <StyledSelect
                  id="living-situation"
                  name="living-situation"
                  required
                  defaultValue={formData.livingSituation}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      livingSituation: e.target.value,
                    })
                  }
                >
                  <option value="">Select an option</option>
                  <option value="male">Alone</option>
                  <option value="Living_with_family">Living with family</option>
                  <option value="Living_with_parents">Living with parents</option>
                  <option value="Living_with_friends_or_roommates">
                    Living with friends or roommates
                  </option>
                  <option value="Homeless">Homeless</option>
                  <option value="Orphanage">Orphanage</option>
                </StyledSelect>
                {errors.livingSituation && (
                  <ErrorMessage>{errors.livingSituation}</ErrorMessage>
                )}
              </StyledChild>

              <StyledChild>
                <StyledLabel htmlFor="main-person-earning-money">
                  Are you the primary earner for your family?
                </StyledLabel>
                <StyledSelect
                  id="main-person-earning-money"
                  name="main-person-earning-money"
                  defaultValue={formData.mainpersonearningmoney}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      mainpersonearningmoney: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select an option</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </StyledSelect>
                {errors.mainpersonearningmoney && (
                  <ErrorMessage>{errors.mainpersonearningmoney}</ErrorMessage>
                )}
              </StyledChild>
            </StyledChildDiv>

            <StyledChildDiv>
              <StyledChild>
                <StyledLabel>Do you take care of anyone at home?</StyledLabel>
                <Select
                  isMulti
                  name="careTaker"
                  options={options}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={options.filter((option) =>
                    formData.careTaker.includes(option.value)
                  )}
                  onChange={handleSelectChange}
                  placeholder="Choose all that apply"
                  styles={customStyles}
                  required
                />
                {errors.careTaker && (
                  <ErrorMessage>{errors.careTaker}</ErrorMessage>
                )}
              </StyledChild>

              <StyledChild>
                <StyledLabel htmlFor="children-below-five">
                  Are you taking care of a child(ren) below 5 years?
                </StyledLabel>
                <StyledSelect
                  id="children-below-five"
                  name="children-below-five"
                  defaultValue={formData.childrenbelowfive}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      childrenbelowfive: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select an option</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </StyledSelect>
                {errors.childrenbelowfive && (
                  <ErrorMessage>{errors.childrenbelowfive}</ErrorMessage>
                )}
              </StyledChild>
            </StyledChildDiv>

            <ButtonWrapper>
              <StyledButton onClick={prevStep}> Previous</StyledButton>
              <StyledButton2 onClick={handleNext}> Next</StyledButton2>
            </ButtonWrapper>
          </StyledForm>
        </ContentWrapper>
      </CenteredContentWrapper>
    </FullScreenBackground>
  );
};

export default PersonalTwo;




const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;
const FullScreenBackground = styled.div`
  background-image: url("/Formbackground.png");
  background-size: cover;
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    padding: 2rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    padding: 0rem !important;
  }
`;

const CenteredContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  padding: 10px;
  @media only screen and (max-width: 420px) {
    flex-direction: column !important;
    padding: 10px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column !important;
    padding: 10px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    min-height: 100% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    min-height: 100% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    /* min-height: 100% !important; */
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.GRAY};
  max-width: 1200px !important;
  min-width: 1200px !important;
  margin: 0 auto;
  border-radius: 40px;
  padding: 3rem !important;
  min-height: 80vh !important;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    padding: 1rem !important;
    border-radius: 24px;
    max-width: 80% !important;
    min-width: 80% !important;
    margin: 0 !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column !important;
    padding: 1rem !important;
    max-width: 100% !important;
    min-width: 80% !important;
    border-radius: 24px;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    /* padding: 3rem 0 !important; */
    max-width: 100% !important;
    min-width: 80% !important;
    border-radius: 24px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    /* padding: 3rem 0 !important; */
  }
`;

const Content = styled.div`
  /* padding: 20px; */
  width: 40% !important;

  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Header = styled.h1`
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes[48]};
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -0.04em;
  text-align: left;
  color: ${colors.DARK_BROWN};
  margin: 0;
  padding: 0;
  width: 50%;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
    padding-bottom: 1rem !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const Ptag = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 500;
  line-height: 25.6px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${colors.DEEP_BROWN};
  width: 70% !important;
  /* margin: 0;
padding: 0; */
`;

const ApplyButton = styled.button`
  background: ${colors.WHITE};
  gap: 10px;
  padding: 1.2rem 3rem;
  border-radius: 10px;
  border: 0px solid ${colors.WHITE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 600;
  cursor: pointer;
`;

const StyledForm = styled.form`
  width: 40% !important;
  @media only screen and (max-width: 420px) {
    width: 100% !important;
    margin-top: 1rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
    margin-top: 1rem !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    width: 50% !important;
  }
`;

const StyledChild = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  @media only screen and (max-width: 420px) {
    width: 100%;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 80%;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    width: 60%;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    /* width: 100%; */
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    width: 30%;
  }
`;
const StyledChildDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    gap: 0;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
    gap: 0;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    flex-direction: column !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    flex-direction: column !important;
    gap: 0;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const StyledLabel = styled.label`
  color: ${colors.BLACK};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 150% */
`;
const StyledInput = styled.input`
  border-radius: var(--Radius-radius-sm, 8px);
  border: 1px solid ${colors.LIGHT_GRAY};
  background: ${colors.DEEP_GRAY};
  color: ${colors.DEEPER_GRAY};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding: 4px 12px 4px 12px !important;
  margin-top: 0.5rem;

  &:focus {
    outline: ${`1px solid ${colors.PINK}`};
    font-family: ${fonts.FONT_FAMILY};
  }

  @media only screen and (max-width: 420px) {
    height: 3vh !important;
    font-size: 12px !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    height: 5vh !important;
    font-size: 12px !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    height: 5vh;
  }
`;
const StyledSelect = styled.select`
  border-radius: var(--Radius-radius-sm, 8px);
  border: 1px solid ${colors.LIGHT_GRAY};
  background: ${colors.DEEP_GRAY};
  color: ${colors.DEEPER_GRAY};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding: 4px 12px 4px 12px !important;
  margin-top: 0.5rem;
  height: 6vh !important;

  &:focus {
    outline: ${`1px solid ${colors.PINK}`};
    font-family: ${fonts.FONT_FAMILY};
  }

  @media only screen and (max-width: 420px) {
    height: 5vh !important;
    font-size: 12px !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    height: 5vh !important;
    font-size: 12px !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    height: 5vh;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 2rem !important;

  @media only screen and (max-width: 420px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    flex-wrap: wrap !important;
  }
`;

const StyledButton = styled.button`
  padding: 1.2rem 3rem;
  background: ${colors.WHITE};
  border-radius: 12px;
  color: ${colors.DARK_GREY};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 600;
  line-height: 16px;
  width: 100% !important;
  border: 0px solid ${colors.ORANGE};
  margin-top: 0rem;
  cursor: pointer;
  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const StyledButton2 = styled.button`
  padding: 1.2rem 3rem;
  background: ${colors.ORANGE};
  border-radius: 12px;
  color: ${colors.WHITE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 600;
  line-height: 16px;
  width: 100% !important;
  border: 0px solid ${colors.ORANGE};
  margin-top: 0rem;
  cursor: pointer;
  &:hover {
    background: ${colors.DARK_BROWN} !important;
  }
  &:active {
    background: ${colors.ORANGE_LIGHT};
  }

  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;


/* eslint-disable no-undef */
// import React from "react";
// import styled from "styled-components";
// import { colors, fonts, fontSizes } from "../../util/Style-Guide";
// import Indicator from "./StepIndicator/Indicator";
// import { useNavigate } from "react-router-dom";
// import { statesInNigeria } from "./statesInNigeria";
// import Select from "react-select";
// import { personalInformationSchemaTwo } from "../../validations/auth.schema";
// import { Formik, Form, Field, ErrorMessage as FormikErrorMessage } from "formik";

// const options = [
//   { value: "no-one", label: "No one" },
//   { value: "my-family", label: "My family" },
//   { value: "my-children", label: "My children" },
//   { value: "other-children", label: "Other children" },
//   { value: "an-elderly-person", label: "An elderly person" },
//   { value: "a-disabled-person", label: "A disabled person" },
// ];

// const PersonalTwo = ({ nextStep, prevStep, formData, setFormData }) => {
//   const navigate = useNavigate();
//   const navigateToHome = () => {
//     navigate("/");
//   };

//   const handleNext = (values) => {
//     setFormData(values);
//     nextStep();
//   };

//   const initialValues = {
//     maritalStatus: formData.maritalStatus || "",
//     residentialAddress: formData.residentialAddress || "",
//     state: formData.state || "",
//     livingSituation: formData.livingSituation || "",
//     mainpersonearningmoney: formData.mainpersonearningmoney || "",
//     careTaker: formData.careTaker || [],
//     childrenbelowfive: formData.childrenbelowfive || "",
//   };

//   const customStyles = {
//     control: (provided) => ({
//       ...provided,
//       borderRadius: "8px",
//       border: `1px solid ${colors.LIGHT_GRAY}`,
//       background: `${colors.DEEP_GRAY}`,
//       color: `${colors.DEEPER_GRAY}`,
//       fontFamily: `${fonts.FONT_FAMILY_BODY}`,
//       fontSize: `${fontSizes.extraSmall}`,
//       fontStyle: "normal",
//       fontWeight: 400,
//       marginTop: "0.5rem",
//       width: "100%",
//     }),
//     menu: (provided) => ({
//       ...provided,
//       marginTop: 0,
//       maxWidth: "95%",
//       overflowX: "hidden",
//     }),
//     menuList: (provided) => ({
//       ...provided,
//       maxHeight: "200px",
//       overflowY: "auto",
//     }),
//     option: (provided, state) => ({
//       ...provided,
//       backgroundColor: state.isSelected ? `${colors.PRIMARY}` : `${colors.DEEP_GRAY}`,
//       color: state.isSelected ? `${colors.WHITE}` : `${colors.DEEPER_GRAY}`,
//       padding: "2px 2px 2px 12px",
//       fontFamily: `${fonts.FONT_FAMILY_BODY}`,
//       fontSize: `${fontSizes.extraSmall}`,
//       cursor: 'pointer',
//       ':hover': {
//         backgroundColor: '#0056b3',
//         color: `${colors.WHITE}`,
//       },
//     }),
//   };

//   return (
//     <FullScreenBackground>
//       <CenteredContentWrapper>
//         <ContentWrapper>
//           <Content>
//             <Header>Personal Information</Header>
//             <Indicator currentStep={2} totalSteps={6} />
//             <Ptag>
//               Let’s get to know you enter your accurate personal information for the program.
//             </Ptag>

//             <ButtonWrapper>
//               <ApplyButton onClick={navigateToHome}>Back to home</ApplyButton>
//             </ButtonWrapper>
//           </Content>

//           <Formik
//             initialValues={initialValues}
//             validationSchema={personalInformationSchemaTwo}
//             onSubmit={handleNext}
//           >
//             {({ values, setFieldValue }) => (
//               <StyledForm>
//                 <StyledChild>
//                   <StyledLabel htmlFor="maritalStatus">Marital Status</StyledLabel>
//                   <Field as={StyledSelect} id="maritalStatus" name="maritalStatus">
//                     <option value="">Select one</option>
//                     <option value="single">Single</option>
//                     <option value="married">Married</option>
//                     <option value="divorced">Divorced</option>
//                     <option value="singleParent">Single parent</option>
//                     <option value="widowed">Widowed</option>
//                   </Field>
//                   <FormikErrorMessage component={ErrorMessage} name="maritalStatus" />
//                 </StyledChild>

//                 <StyledChildDiv>
//                   <StyledChild>
//                     <StyledLabel htmlFor="residentialAddress">Specific Home Address</StyledLabel>
//                     <Field
//                       as={StyledInput}
//                       id="residentialAddress"
//                       name="residentialAddress"
//                       placeholder="Enter your address"
//                     />
//                     <FormikErrorMessage component={ErrorMessage} name="residentialAddress" />
//                   </StyledChild>
//                   <StyledChild>
//                     <StyledLabel htmlFor="state">State</StyledLabel>
//                     <Field as={StyledSelect} id="state" name="state">
//                       <option value="">Select State</option>
//                       {statesInNigeria.map((state) => (
//                         <option key={state.value} value={state.value}>
//                           {state.name}
//                         </option>
//                       ))}
//                     </Field>
//                     <FormikErrorMessage component={ErrorMessage} name="state" />
//                   </StyledChild>
//                 </StyledChildDiv>

//                 <StyledChildDiv>
//                   <StyledChild>
//                     <StyledLabel htmlFor="livingSituation">Current living situation</StyledLabel>
//                     <Field as={StyledSelect} id="livingSituation" name="livingSituation">
//                       <option value="">Living Situation</option>
//                       <option value="alone">Alone</option>
//                       <option value="family">Living with family</option>
//                       <option value="parents">Living with parents</option>
//                       <option value="friends">Living with friends or roommates</option>
//                       <option value="homeless">Homeless</option>
//                       <option value="orphanage">Orphanage</option>
//                     </Field>
//                     <FormikErrorMessage component={ErrorMessage} name="livingSituation" />
//                   </StyledChild>

//                   <StyledChild>
//                     <StyledLabel htmlFor="mainpersonearningmoney">Are you the primary earner for your family?</StyledLabel>
//                     <Field as={StyledSelect} id="mainpersonearningmoney" name="mainpersonearningmoney">
//                       <option value="">Person earning money</option>
//                       <option value="yes">Yes</option>
//                       <option value="no">No</option>
//                     </Field>
//                     <FormikErrorMessage component={ErrorMessage} name="mainpersonearningmoney" />
//                   </StyledChild>
//                 </StyledChildDiv>

//                 <StyledChildDiv>
//                   <StyledChild>
//                     <StyledLabel>Do you take care of anyone at home?</StyledLabel>
//                     <Select
//                       isMulti
//                       name="careTaker"
//                       options={options}
//                       className="basic-multi-select"
//                       classNamePrefix="select"
//                       value={options.filter((option) =>
//                         values.careTaker.includes(option.value)
//                       )}
//                       onChange={(selectedOptions) =>
//                         setFieldValue(
//                           "careTaker",
//                           selectedOptions ? selectedOptions.map((option) => option.value) : []
//                         )
//                       }
//                       placeholder="Choose all that apply"
//                       styles={customStyles}
//                     />
//                     <FormikErrorMessage component={ErrorMessage} name="careTaker" />
//                   </StyledChild>

//                   <StyledChild>
//                     <StyledLabel htmlFor="childrenbelowfive">Are you taking care of a child(ren) below 5 years?</StyledLabel>
//                     <Field as={StyledSelect} id="childrenbelowfive" name="childrenbelowfive">
//                       <option value="">Select one</option>
//                       <option value="yes">Yes</option>
//                       <option value="no">No</option>
//                     </Field>
//                     <FormikErrorMessage component={ErrorMessage} name="childrenbelowfive" />
//                   </StyledChild>
//                 </StyledChildDiv>

//                 <ButtonWrapper>
//                   <StyledButton type="button" onClick={prevStep}>Previous</StyledButton>
//                   <StyledButton2 type="submit">Next</StyledButton2>
//                 </ButtonWrapper>
//               </StyledForm>
//             )}
//           </Formik>
//         </ContentWrapper>
//       </CenteredContentWrapper>
//     </FullScreenBackground>
//   );
// };

// export default PersonalTwo;