import React, { useState } from "react";
import Indicator from "../StepIndicator/Indicator";
import { colors, fonts, fontSizes } from "../../../util/Style-Guide";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const Page3 = ({ nextStep, prevStep, formData, setFormData }) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const navigateToHome = () => {
    navigate("/");
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.nextofkinname) newErrors.nextofkinname = "This is required";

    if (!formData.relationshipNextofKin)
      newErrors.relationshipNextofKin = "This is required";

    if (
      formData.relationshipNextofKin === "Other" &&
      !formData.nextofkinnameothers
    )
      newErrors.nextofkinnameothers = "This field is required";

    if (!formData.nextofkinphone) {
      newErrors.nextofkinphone = "Phone number is required";
    } else if (!/^\d+$/.test(formData.nextofkinphone)) {
      newErrors.nextofkinphone = "Phone number must be numeric";
    }
    if (!formData.residentialAddressnextofkin)
      newErrors.residentialAddressnextofkin =
        "Specific home address is required";

    const validateEmail = (nextofkinemail) => {
      if (!nextofkinemail) {
        return "Email field is required";
      }
      if (!nextofkinemail.includes("@")) {
        return "Email must contain an '@' symbol.";
      }
      if (!nextofkinemail.includes(".")) {
        return "Email must contain a '.' character.";
      }

      if (!/\S+@\S+\.\S+/.test(nextofkinemail)) {
        return "Email must be a valid email address, containing an '@' symbol and a '.' character.";
      }
      return "";
    };

    const emailError = validateEmail(formData.nextofkinemail);
    if (emailError) {
      newErrors.nextofkinemail = emailError;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextStep = () => {
    if (validateForm()) {
      nextStep();
    }
  };
  return (
    <FullScreenBackground>
      <CenteredContentWrapper>
        <ContentWrapper>
          <Content>
            <Header>Program Interest</Header>
            <Indicator currentStep={6} totalSteps={7} />
            <Ptag>
              Let’s get to know you enter your accurate Background & Experience
              information for the program.
            </Ptag>
            <ButtonWrapper>
              <ApplyButton onClick={navigateToHome}>Back to home</ApplyButton>
            </ButtonWrapper>
          </Content>
          <StyledForm>
            <StyledChildDiv>
              <StyledChild>
                <StyledLabel htmlFor="nextofkinname">
                  Full name of next of kin
                </StyledLabel>
                <StyledInput
                  type="text"
                  id="nextofkinname"
                  name="nextofkinname"
                  placeholder="Enter your first name"
                  value={formData.nextofkinname}
                  onChange={(e) =>
                    setFormData({ ...formData, nextofkinname: e.target.value })
                  }
                  required
                />
                {errors.nextofkinname && (
                  <ErrorMessage>{errors.nextofkinname}</ErrorMessage>
                )}
              </StyledChild>

              <StyledChild>
                <StyledLabel htmlFor="relationshipNextofKin">
                  Relationship to next of kin
                </StyledLabel>
                <StyledSelect
                  id="relationshipNextofKin"
                  name="relationshipNextofKin"
                  defaultValue={formData.relationshipNextofKin}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      relationshipNextofKin: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select an option</option>
                  <option value="Spouse">Spouse</option>
                  <option value="Child">Child</option>
                  <option value="Parent">Parent</option>
                  <option value="Sibling">Sibling</option>
                  <option value="Grandparent">Grandparent</option>
                  <option value="Grandchild">Grandchild</option>
                  <option value="Aunt_Uncle">Aunt/Uncle</option>
                  <option value="Niece_Nephew">Niece/Nephew</option>
                  <option value="Cousin">Cousin</option>
                  <option value="Guardian">Guardian</option>
                  <option value="In_law">In-law</option>
                  <option value="Friend">Friend</option>
                  <option value="Colleague">Colleague</option>
                  <option value="Other">Other</option>
                </StyledSelect>
                {errors.relationshipNextofKin && (
                  <ErrorMessage>{errors.relationshipNextofKin}</ErrorMessage>
                )}
              </StyledChild>
            </StyledChildDiv>
            {formData.relationshipNextofKin === "Other" && (
              <StyledChild>
                <StyledLabel htmlFor="nextofkinnameothers">
                  If others, please specify
                </StyledLabel>
                <StyledInput
                  type="text"
                  id="nextofkinnameothers"
                  name="nextofkinnameothers"
                  placeholder="Enter your first name"
                  value={formData.nextofkinnameothers}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      nextofkinnameothers: e.target.value,
                    })
                  }
                  required
                />
                {errors.nextofkinnameothers && (
                  <ErrorMessage>{errors.nextofkinnameothers}</ErrorMessage>
                )}
              </StyledChild>
            )}

            <StyledChildDiv>
              <StyledChild>
                <StyledLabel htmlFor="phone">
                  Next of kin next of kin phone number
                </StyledLabel>
                <StyledInput
                  type="tel"
                  id="nextofkinphone"
                  name="nextofkinphone"
                  placeholder="Enter your phone number"
                  required
                  value={formData.nextofkinphone}
                  onChange={(e) =>
                    setFormData({ ...formData, nextofkinphone: e.target.value })
                  }
                  pattern="[0-9]*"
                />
                {errors.nextofkinphone && (
                  <ErrorMessage>{errors.nextofkinphone}</ErrorMessage>
                )}
              </StyledChild>

              <StyledChild>
                <StyledLabel htmlFor="email">Email</StyledLabel>
                <StyledInput
                  type="email"
                  id="nextofkinemail"
                  name="nextofkinemail"
                  placeholder="Enter your email"
                  value={formData.nextofkinemail}
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, nextofkinemail: e.target.value })
                  }
                />
                {errors.nextofkinemail && (
                  <ErrorMessage>{errors.nextofkinemail}</ErrorMessage>
                )}
              </StyledChild>
            </StyledChildDiv>

            <StyledChild>
              <StyledLabel htmlFor="residentialAddressnextofkin">
                Specific Home Address
              </StyledLabel>
              <StyledInput
                type="residentialAddressnextofkin"
                id="residentialAddressnextofkin"
                name="residentialAddressnextofkin"
                placeholder="Enter your address"
                defaultValue={formData.residentialAddressnextofkin}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    residentialAddressnextofkin: e.target.value,
                  })
                }
                required
              />
              {errors.residentialAddressnextofkin && (
                <ErrorMessage>
                  {errors.residentialAddressnextofkin}
                </ErrorMessage>
              )}
            </StyledChild>
            <ButtonWrapper>
              <StyledButton onClick={prevStep}> Previous</StyledButton>
              <StyledButton2 onClick={handleNextStep}> Next</StyledButton2>
            </ButtonWrapper>
          </StyledForm>
        </ContentWrapper>
      </CenteredContentWrapper>
    </FullScreenBackground>
  );
};

export default Page3;
const ErrorMessage = styled.div`
  color: red;
  margin-top: 0.5rem;
  font-size: ${fontSizes.small};
`;

const FullScreenBackground = styled.div`
  background-image: url("/Formbackground.png");
  background-size: cover;
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    padding: 2rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    padding: 0rem !important;
  }
`;

const CenteredContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  padding: 10px;
  @media only screen and (max-width: 420px) {
    flex-direction: column !important;
    padding: 10px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column !important;
    padding: 10px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    min-height: 100% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    min-height: 100% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    /* min-height: 100% !important; */
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.GRAY};
  max-width: 1200px !important;
  min-width: 1200px !important;
  margin: 0 auto;
  border-radius: 40px;
  padding: 3rem !important;
  min-height: 80vh !important;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    padding: 1rem !important;
    border-radius: 24px;
    max-width: 80% !important;
    min-width: 80% !important;
    margin: 0 !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column !important;
    padding: 1rem !important;
    max-width: 100% !important;
    min-width: 80% !important;
    border-radius: 24px;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    /* padding: 3rem 0 !important; */
    max-width: 100% !important;
    min-width: 80% !important;
    border-radius: 24px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    /* padding: 3rem 0 !important; */
  }
`;

const Content = styled.div`
  /* padding: 20px; */
  width: 40% !important;

  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Header = styled.h1`
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes[48]};
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -0.04em;
  text-align: left;
  color: ${colors.DARK_BROWN};
  margin: 0;
  padding: 0;
  width: 50%;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
    padding-bottom: 1rem !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const Ptag = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 500;
  line-height: 25.6px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${colors.DEEP_BROWN};
  width: 70% !important;
  /* margin: 0;
padding: 0; */
`;

const ApplyButton = styled.button`
  background: ${colors.WHITE};
  gap: 10px;
  padding: 1.2rem 3rem;
  border-radius: 10px;
  border: 0px solid ${colors.WHITE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 600;
  cursor: pointer;
`;

const StyledForm = styled.form`
  width: 40% !important;
  @media only screen and (max-width: 420px) {
    width: 100% !important;
    margin-top: 1rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
    margin-top: 1rem !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    width: 50% !important;
  }
`;

const StyledChild = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  @media only screen and (max-width: 420px) {
    width: 100%;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 80%;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    width: 60%;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    /* width: 100%; */
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    width: 30%;
  }
`;
const StyledChildDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    gap: 0;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
    gap: 0;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    flex-direction: column !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    flex-direction: column !important;
    gap: 0;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const StyledLabel = styled.label`
  color: ${colors.BLACK};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 150% */
`;
const StyledInput = styled.input`
  border-radius: var(--Radius-radius-sm, 8px);
  border: 1px solid ${colors.LIGHT_GRAY};
  background: ${colors.DEEP_GRAY};
  color: ${colors.DEEPER_GRAY};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding: 4px 12px 4px 12px !important;
  margin-top: 0.5rem;

  &:focus {
    outline: ${`1px solid ${colors.PINK}`};
    font-family: ${fonts.FONT_FAMILY};
  }

  @media only screen and (max-width: 420px) {
    height: 3vh !important;
    font-size: 12px !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    height: 5vh !important;
    font-size: 12px !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    height: 5vh;
  }
`;
const StyledSelect = styled.select`
  border-radius: var(--Radius-radius-sm, 8px);
  border: 1px solid ${colors.LIGHT_GRAY};
  background: ${colors.DEEP_GRAY};
  color: ${colors.DEEPER_GRAY};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding: 4px 12px 4px 12px !important;
  margin-top: 0.5rem;
  height: 6vh !important;

  &:focus {
    outline: ${`1px solid ${colors.PINK}`};
    font-family: ${fonts.FONT_FAMILY};
  }

  @media only screen and (max-width: 420px) {
    height: 5vh !important;
    font-size: 12px !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    height: 5vh !important;
    font-size: 12px !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    height: 5vh;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 2rem !important;

  @media only screen and (max-width: 420px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    flex-wrap: wrap !important;
  }
`;

const StyledButton = styled.button`
  padding: 1.2rem 3rem;
  background: ${colors.WHITE};
  border-radius: 12px;
  color: ${colors.DARK_GREY};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 600;
  line-height: 16px;
  width: 100% !important;
  border: 0px solid ${colors.ORANGE};
  margin-top: 0rem;
  cursor: pointer;
  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const StyledButton2 = styled.button`
  padding: 1.2rem 3rem;
  background: ${colors.ORANGE};
  border-radius: 12px;
  color: ${colors.WHITE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 600;
  line-height: 16px;
  width: 100% !important;
  border: 0px solid ${colors.ORANGE};
  margin-top: 0rem;
  cursor: pointer;
  &:hover {
    background: ${colors.DARK_BROWN} !important;
  }
  &:active {
    background: ${colors.ORANGE_LIGHT};
  }

  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
