/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colors, fonts, fontSizes } from "../../util/Style-Guide";
import Indicator from "./StepIndicator/Indicator";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { getRandomIntakeAgentID } from "../../util/intakeAgentUtils";

const Personal = ({ nextStep, formData, setFormData }) => {
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate("/");
  };

  useEffect(() => {
    if (!formData.uniqueId) {
      // Generate a unique ID if it doesn't exist
      const uniqueId = `${uuidv4()}`;
      setFormData((prevData) => ({ ...prevData, uniqueId }));
    }
  }, [formData, setFormData]);

  useEffect(() => {
    if (!formData.instanceID) {
      // Generate a unique ID if it doesn't exist
      const instanceID = `uuid:${uuidv4()}`;
      setFormData((prevData) => ({ ...prevData, instanceID }));
    }
  });

  useEffect(() => {
    if (!formData.select_intake_agent) {
      const select_intake_agent = getRandomIntakeAgentID();
      setFormData((prevData) => ({ ...prevData, select_intake_agent }));
    }
  }, [formData, setFormData]);

  const handleNext = (e) => {
    e.preventDefault();
    if (validateForm()) {
      nextStep();
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.dob) newErrors.dob = "Date of birth is required";
    if (!formData.gender) newErrors.gender = "Gender is required";
    if (!formData.phone) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d+$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be numeric";
    }
    if (!formData.phonewhatsapp) {
      newErrors.phonewhatsapp = "Phone number is required";
    } else if (!/^\d+$/.test(formData.phonewhatsapp)) {
      newErrors.phonewhatsapp = "Phone number must be numeric";
    }
    // if (!formData.email && !/\S+@\S+\.\S+/.test(!formData.email)) {
    //   newErrors.email = "Email must be a valid email address, containing an '@' symbol and a '.' character.";
    // }
    const validateEmail = (email) => {
      if (!email) {
        return "Email field is required";
      }
      if (!email.includes("@")) {
        return "Email must contain an '@' symbol.";
      }
      if (!email.includes(".")) {
        return "Email must contain a '.' character.";
      }
      if (!/\S+@\S+\.\S+/.test(email)) {
        return "Email must be a valid email address, containing an '@' symbol and a '.' character.";
      }
      return "";
    };

    const emailError = validateEmail(formData.email);
    if (emailError) {
      newErrors.email = emailError;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <FullScreenBackground>
      <CenteredContentWrapper>
        <ContentWrapper>
          <Content>
            <Header>Personal Information</Header>
            <Indicator currentStep={1} totalSteps={6} />
            <Ptag>
              Let’s get to know you enter your accurate personal information for
              the program.
            </Ptag>

            <ButtonWrapper>
              <ApplyButton onClick={navigateToHome}>Back to home</ApplyButton>
            </ButtonWrapper>
          </Content>

          <StyledForm>
            <input
              type="hidden"
              name="uniqueId"
              value={formData.uniqueId || ""}
            />
            <input
              type="hidden"
              name="instanceID"
              value={formData.instanceID || ""}
            />
            <input
              type="hidden"
              name="intake_agent"
              value={formData.select_intake_agent || ""}
            />
            <StyledChildDiv>
              <StyledChild>
                <StyledLabel htmlFor="first-name">First Name</StyledLabel>
                <StyledInput
                  type="text"
                  id="first-name"
                  name="firstName"
                  placeholder="Enter your first name"
                  value={formData.firstName}
                  onChange={(e) =>
                    setFormData({ ...formData, firstName: e.target.value })
                  }
                  required
                />
                {errors.firstName && (
                  <ErrorMessage>{errors.firstName}</ErrorMessage>
                )}
              </StyledChild>
              <StyledChild>
                <StyledLabel htmlFor="middle-name">Middle Name</StyledLabel>
                <StyledInput
                  type="text"
                  id="middle-name"
                  name="middleName"
                  placeholder="Enter your middle name"
                  value={formData.middleName}
                  onChange={(e) =>
                    setFormData({ ...formData, middleName: e.target.value })
                  }
                />
              </StyledChild>
            </StyledChildDiv>
            <StyledChild>
              <StyledLabel htmlFor="last-name">Last Name</StyledLabel>
              <StyledInput
                type="text"
                id="last-name"
                name="lastName"
                placeholder="Enter your last name"
                value={formData.lastName}
                onChange={(e) =>
                  setFormData({ ...formData, lastName: e.target.value })
                }
                required
              />
              {errors.lastName && (
                <ErrorMessage>{errors.lastName}</ErrorMessage>
              )}
            </StyledChild>

            <StyledChildDiv>
              <StyledChild>
                <StyledLabel htmlFor="dob">Date of Birth</StyledLabel>
                <StyledInput
                  type="date"
                  id="dob"
                  name="dob"
                  required
                  value={formData.dob}
                  onChange={(e) =>
                    setFormData({ ...formData, dob: e.target.value })
                  }
                />
                {errors.dob && <ErrorMessage>{errors.dob}</ErrorMessage>}
              </StyledChild>

              <StyledChild>
                <StyledLabel htmlFor="gender">Gender</StyledLabel>
                <StyledSelect
                  id="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={(e) =>
                    setFormData({ ...formData, gender: e.target.value })
                  }
                  required
                >
                  <option value="">Select an option</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </StyledSelect>
                {errors.gender && <ErrorMessage>{errors.gender}</ErrorMessage>}
              </StyledChild>
            </StyledChildDiv>
            <StyledChildDiv>
              <StyledChild>
                <StyledLabel htmlFor="phone">Phone Number</StyledLabel>
                <StyledInput
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="Enter your phone number"
                  required
                  value={formData.phone}
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                  pattern="[0-9]*"
                />
                {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
              </StyledChild>
              <StyledChild>
                <StyledLabel htmlFor="phone">Phone Number WhatsApp</StyledLabel>
                <StyledInput
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="Enter your phone number"
                  required
                  value={formData.phonewhatsapp}
                  onChange={(e) =>
                    setFormData({ ...formData, phonewhatsapp: e.target.value })
                  }
                  pattern="[0-9]*"
                />
                {errors.phonewhatsapp && (
                  <ErrorMessage>{errors.phonewhatsapp}</ErrorMessage>
                )}
              </StyledChild>
            </StyledChildDiv>
            <StyledChild>
              <StyledLabel htmlFor="email">Email</StyledLabel>
              <StyledInput
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                required
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
              {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
            </StyledChild>

            <StyledButton onClick={handleNext}> Next</StyledButton>
          </StyledForm>
        </ContentWrapper>
      </CenteredContentWrapper>
    </FullScreenBackground>
  );
};

export default Personal;

const ErrorMessage = styled.span`
  color: red;
  font-size: 9px;
  margin-top: 1px;
  font-family: ${fonts.FONT_FAMILY_BODY};
  padding: 0;
`;

const FullScreenBackground = styled.div`
  background-image: url("/Formbackground.png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    padding: 2rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    padding: 0rem !important;
  }
`;

const CenteredContentWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  padding: 10px;
  @media only screen and (max-width: 420px) {
    flex-direction: column !important;
    padding: 10px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column !important;
    padding: 10px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    min-height: 100% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    min-height: 100% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    /* min-height: 100% !important; */
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.GRAY};
  max-width: 1200px !important;
  min-width: 1200px !important;
  margin: 0 auto;
  border-radius: 40px;
  padding: 3rem !important;
  min-height: 80vh !important;

  @media only screen and (max-width: 420px) {
    flex-direction: column;
    padding: 1rem !important;
    border-radius: 24px;
    max-width: 80% !important;
    min-width: 80% !important;
    margin: 0 !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column !important;
    padding: 1rem !important;
    max-width: 100% !important;
    min-width: 80% !important;
    border-radius: 24px;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    /* padding: 3rem 0 !important; */
    max-width: 100% !important;
    min-width: 80% !important;
    border-radius: 24px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    /* padding: 3rem 0 !important; */
  }
`;

const Content = styled.div`
  width: 40% !important;

  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Header = styled.h1`
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes[48]};
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -0.04em;
  text-align: left;
  color: ${colors.DARK_BROWN};
  margin: 0;
  padding: 0;
  width: 50%;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
    padding-bottom: 1rem !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const Ptag = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 500;
  line-height: 25.6px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${colors.DEEP_BROWN};
  width: 70% !important;
  @media only screen and (max-width: 420px) {
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 2rem !important;
  @media only screen and (max-width: 420px) {
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const ApplyButton = styled.button`
  background: ${colors.WHITE};
  gap: 10px;
  padding: 1.2rem 3rem;
  border-radius: 10px;
  border: 0px solid ${colors.WHITE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 600;
  cursor: pointer;
  @media only screen and (max-width: 420px) {
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const StyledForm = styled.form`
  width: 40% !important;
  @media only screen and (max-width: 420px) {
    width: 100% !important;
    margin-top: 1rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
    margin-top: 1rem !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    width: 50% !important;
  }
`;

const StyledChild = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    width: 60%;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    /* width: 100%; */
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    width: 30%;
  }
`;
const StyledChildDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    gap: 0;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
    gap: 0;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    flex-direction: column !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    flex-direction: column !important;
    gap: 0;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const StyledLabel = styled.label`
  color: ${colors.BLACK};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 150% */
  @media only screen and (max-width: 420px) {
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const StyledInput = styled.input`
  border-radius: var(--Radius-radius-sm, 8px);
  border: 1px solid ${colors.LIGHT_GRAY};
  background: ${colors.DEEP_GRAY};
  color: ${colors.DEEPER_GRAY};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding: 4px 12px 4px 12px !important;
  margin-top: 0.5rem;
  &:focus {
    outline: ${`1px solid ${colors.PINK}`};
    font-family: ${fonts.FONT_FAMILY};
  }

  @media only screen and (max-width: 420px) {
    height: 3vh !important;
    font-size: 12px !important;
    /* width: 100% !important; */
    padding: 4px 4px !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    height: 5vh !important;
    font-size: 12px !important;
    /* width: 100% !important; */
    padding: 4px 4px !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    height: 5vh;
  }
`;
const StyledSelect = styled.select`
  border-radius: var(--Radius-radius-sm, 8px);
  border: 1px solid ${colors.LIGHT_GRAY};
  background: ${colors.DEEP_GRAY};
  color: ${colors.DEEPER_GRAY};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding: 4px 12px 4px 12px !important;
  margin-top: 0.5rem;
  height: 6.3vh !important;
  &:focus {
    outline: ${`1px solid ${colors.PINK}`};
    font-family: ${fonts.FONT_FAMILY};
  }

  @media only screen and (max-width: 420px) {
    height: 4.3vh !important;
    font-size: 12px !important;
    width: 100% !important;
    padding: 4px 4px !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    height: 5.2vh !important;
    font-size: 12px !important;
    width: 100% !important;
    padding: 4px 4px !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    height: 5vh;
  }
`;

const StyledButton = styled.button`
  padding: 1.2rem 3rem;
  background: ${colors.ORANGE};
  border-radius: 12px;
  color: ${colors.WHITE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 600;
  line-height: 16px;
  width: 100% !important;
  border: 0px solid ${colors.ORANGE};
  margin-top: 0rem;
  cursor: pointer;

  &:hover {
    background: ${colors.DARK_BROWN} !important;
  }
  &:active {
    background: ${colors.ORANGE_LIGHT};
  }

  @media only screen and (max-width: 420px) {
    /* padding: 0.5rem; */
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

/* eslint-disable no-undef */
// import React, { useState, useEffect } from "react";
// import styled from "styled-components";
// import { colors, fonts, fontSizes } from "../../util/Style-Guide";
// import Indicator from "./StepIndicator/Indicator";
// import { useNavigate } from "react-router-dom";
// import { v4 as uuidv4 } from "uuid";
// import { personalInformationSchema } from "../../validations/auth.schema";
// import {
//   Formik,
//   Form,
//   Field,
//   ErrorMessage as FormikErrorMessage,
// } from "formik";
// import "react-phone-number-input/style.css";
// import { getRandomIntakeAgentID } from "../../util/intakeAgentUtils";

// const Personal = ({ nextStep, formData, setFormData }) => {
//   const navigate = useNavigate();
//   const navigateToHome = () => {
//     navigate("/");
//   };

//   useEffect(() => {
//     if (!formData.uniqueId) {
//       // Generate a unique ID if it doesn't exist
//       const uniqueId = `${uuidv4()}`;
//       setFormData((prevData) => ({ ...prevData, uniqueId }));
//     }
//   }, [formData, setFormData]);

//   useEffect(() => {
//     if (!formData.instanceID) {
//       // Generate a unique ID if it doesn't exist
//       const instanceID = `uuid:${uuidv4()}`;
//       setFormData((prevData) => ({ ...prevData, instanceID }));
//     }
//   }, []);

//   useEffect(() => {
//     if (!formData.select_intake_agent) {
//       const select_intake_agent = getRandomIntakeAgentID();
//       setFormData((prevData) => ({ ...prevData, select_intake_agent }));
//     }
//   }, [formData, setFormData]);

//   const handleNext = (values) => {
//     setFormData({ ...formData, ...values });
//     nextStep();
//   };

//   const initialValues={
//     uniqueId: formData.uniqueId || "",
//     instanceID: formData.instanceID || "",
//     select_intake_agent: formData.select_intake_agent || "",
//     firstName: formData.firstName || "",
//     middleName: formData.middleName || "",
//     lastName: formData.lastName || "",
//     dateOfBirth: formData.dateOfBirth || "",
//     gender: formData.gender || "",
//     phone: formData.phone || "",
//     email: formData.email || "",
//   };

//   console.log("Initial Values:", initialValues);
//   console.log("Form Data:", formData);

//   return (
//     <FullScreenBackground>
//       <CenteredContentWrapper>
//         <ContentWrapper>
//           <Content>
//             <Header>Personal Information</Header>
//             <Indicator currentStep={1} totalSteps={6} />
//             <Ptag>
//               Let’s get to know you enter your accurate personal information for
//               the program.
//             </Ptag>

//             <ButtonWrapper>
//               <ApplyButton onClick={navigateToHome}>Back to home</ApplyButton>
//             </ButtonWrapper>
//           </Content>

//           <Formik
//             initialValues={initialValues}
//             validationSchema={personalInformationSchema}
//             onSubmit={handleNext}
//           >
//             {({ values, handleChange, setFieldValue }) => (
//               <StyledForm>
//                 <input type="hidden" name="uniqueId" value={values.uniqueId} />
//                 <input
//                   type="hidden"
//                   name="instanceID"
//                   value={values.instanceID}
//                 />
//                 <input
//                   type="hidden"
//                   name="select_intake_agent"
//                   value={values.select_intake_agent}
//                 />
//                 <StyledChildDiv>
//                   <StyledChild>
//                     <StyledLabel htmlFor="first-name">First Name</StyledLabel>
//                     <StyledInput
//                       type="text"
//                       id="first-name"
//                       name="firstName"
//                       value={values.firstName}
//                       onChange={(e) => {
//                         handleChange(e);
//                         setFieldValue("firstName", e.target.value);
//                       }}
//                       placeholder="Enter your first name"
//                       className="styled-input"
//                     />
//                     <FormikErrorMessage
//                       name="firstName"
//                       component={ErrorMessage}
//                       className="error-message"
//                     />
//                   </StyledChild>
//                   <StyledChild>
//                     <StyledLabel htmlFor="middle-name">Middle Name</StyledLabel>
//                     <StyledInput
//                       type="text"
//                       id="middle-name"
//                       name="middleName"
//                       value={values.middleName}
//                       onChange={(e) => {
//                         handleChange(e);
//                         setFieldValue("middleName", e.target.value);
//                       }}
//                       placeholder="Enter your middle name"
//                       className="styled-input"
//                     />
//                     <FormikErrorMessage
//                       name="middleName"
//                       component={ErrorMessage}
//                       className="error-message"
//                     />
//                   </StyledChild>
//                 </StyledChildDiv>
//                 <StyledChild>
//                   <StyledLabel htmlFor="last-name">Last Name</StyledLabel>
//                   <StyledInput
//                     type="text"
//                     id="last-name"
//                     name="lastName"
//                     value={values.lastName}
//                     onChange={(e) => {
//                       handleChange(e);
//                       setFieldValue("lastName", e.target.value);
//                     }}
//                     placeholder="Enter your last name"
//                     className="styled-input"
//                   />
//                   <FormikErrorMessage
//                     name="lastName"
//                     component={ErrorMessage}
//                     className="error-message"
//                   />
//                 </StyledChild>

//                 <StyledChildDiv>
//                   <StyledChild>
//                     <StyledLabel htmlFor="dob">Date of Birth</StyledLabel>
//                     <StyledInput
//                       type="date"
//                       id="dob"
//                       name="dateOfBirth"
//                       value={values.dateOfBirth}
//                       onChange={(e) => {
//                         handleChange(e);
//                         setFieldValue("dateOfBirth", e.target.value);
//                       }}
//                       className="styled-input"
//                     />
//                     <FormikErrorMessage
//                       name="dateOfBirth"
//                       component={ErrorMessage}
//                       className="error-message"
//                     />
//                   </StyledChild>

//                   <StyledChild>
//                     <StyledLabel htmlFor="gender">Gender</StyledLabel>
//                     <StyledSelect
//                       id="gender"
//                       name="gender"
//                       value={values.gender}
//                       onChange={(e) => {
//                         handleChange(e);
//                         setFieldValue("gender", e.target.value);
//                       }}
//                       className="styled-select"
//                     >
//                       <option value="">Select Gender</option>
//                       <option value="male">Male</option>
//                       <option value="female">Female</option>
//                     </StyledSelect>
//                     <FormikErrorMessage
//                       name="gender"
//                       component={ErrorMessage}
//                       className="error-message"
//                     />
//                   </StyledChild>
//                 </StyledChildDiv>
//                 <StyledChildDiv>
//                   <StyledChild>
//                     <StyledLabel htmlFor="phone">Phone Number</StyledLabel>
//                     <StyledInput
//                       type="tel"
//                       id="phone"
//                       name="phone"
//                       value={values.phone}
//                       onChange={(e) => {
//                         handleChange(e);
//                         setFieldValue("phone", e.target.value);
//                       }}
//                       placeholder="Enter your phone number"
//                       className="styled-input"
//                     />
//                     <FormikErrorMessage
//                       name="phone"
//                       component={ErrorMessage}
//                       className="error-message"
//                     />
//                   </StyledChild>
//                   <StyledChild>
//                     <StyledLabel htmlFor="email">Email</StyledLabel>
//                     <StyledInput
//                       type="email"
//                       id="email"
//                       name="email"
//                       value={values.email}
//                       onChange={(e) => {
//                         handleChange(e);
//                         setFieldValue("email", e.target.value);
//                       }}
//                       placeholder="Enter your email"
//                       className="styled-input"
//                     />
//                     <FormikErrorMessage
//                       name="email"
//                       component={ErrorMessage}
//                       className="error-message"
//                     />
//                   </StyledChild>
//                 </StyledChildDiv>

//                 <StyledButton type="submit">Next</StyledButton>
//               </StyledForm>
//             )}
//           </Formik>
//         </ContentWrapper>
//       </CenteredContentWrapper>
//     </FullScreenBackground>
//   );
// };

// export default Personal;
