/* eslint-disable no-undef */
import React, { useState } from "react";
import styled from "styled-components";
import { colors, fonts, fontSizes } from "../../util/Style-Guide";
import Indicator from "./StepIndicator/Indicator";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Terms from "./Modal/Terms";

const KoboForm = ({ submitData, prevStep, formData, setFormData }) => {
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(
    formData.terms_and_conditions === "yes"
  );

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  const navigateToHome = () => {
    navigate("/");
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (isChecked) {
  //     if (isSubmitted) {
  //       toast.info("Form submitted. You can't resubmit this form.");
  //     } else {
  //       setIsSubmitted(true); // Disable the button immediately after the first click
  //       try {
  //         await submitData();
  //         toast.success("Form submitted successfully!");
  //       } catch (error) {
  //         toast.error("There was an error submitting the form.");
  //         setIsSubmitted(false); // Re-enable the button if submission fails
  //       }
  //     }
  //   } else {
  //     alert("Please agree to the terms and conditions.");
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isChecked) {
      try {
        if (isSubmitted) {
          toast.info("Form submitted. You can't resubmit this form.");
        } else {
          await submitData();
          setIsSubmitted(true);
          toast.success("Form submitted successfully!");
        }
      } catch (error) {
        toast.error("There was an error submitting the form.");
      }
    } else {
      alert("Please agree to the terms and conditions.");
    }
  };

  const handleCheckboxChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    setFormData({
      ...formData,
      terms_and_conditions: newCheckedState ? "yes" : "no",
    });
  };

  // const handlePrivacyCheckboxChange = () => {
  //   const newPrivacyCheckedState = !isPrivacyChecked;
  //   setIsPrivacyChecked(newPrivacyCheckedState);
  //   setFormData({
  //     ...formData,
  //     privacy_policy: newPrivacyCheckedState ? "yes" : "no",
  //   });
  // };

  return (
    <FullScreenBackground>
      <CenteredContentWrapper>
        <ContentWrapper>
          <Content>
            <Header>Confirmation</Header>
            <Indicator currentStep={7} totalSteps={7} />
            <Ptag>
              Let’s get to know you enter your accurate personal information for
              the program.
            </Ptag>

            <ApplyButton onClick={navigateToHome}>Back to home</ApplyButton>
          </Content>
          <StyledForm onSubmit={handleSubmit}>
            <Ptag>
              By submitting form, you agree to our terms of service and agree
              for us to use your personal information during the selection
              process.
            </Ptag>
            <CheckboxWrapper>
              <input
                type="checkbox"
                id="termsAndConditions"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="termsAndConditions">
                I agree to the{" "}
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => setIsTermsModalOpen(true)}
                >
                  terms and conditions
                </span>
              </label>
            </CheckboxWrapper>

            <ButtonWrapper>
              <StyledButton type="button" onClick={prevStep}>
                {" "}
                Previous
              </StyledButton>

              <StyledButton2 type="submit" disabled={isSubmitted}>
                {isSubmitted ? "Form Submitted" : "Submit"}
              </StyledButton2>

              {/* <StyledButton2 type="submit" isSubmitted={isSubmitted}>
                {" "}
                {isSubmitted ? "Form Submitted" : "Submit"}
              </StyledButton2> */}
            </ButtonWrapper>
          </StyledForm>
        </ContentWrapper>
        <ToastContainer />
        {isTermsModalOpen && (
          <Terms onClose={() => setIsTermsModalOpen(false)} />
        )}
      </CenteredContentWrapper>
    </FullScreenBackground>
  );
};

export default KoboForm;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  input[type="checkbox"] {
    margin-right: 10px;
  }
`;

const FullScreenBackground = styled.div`
  background-image: url("/Formbackground.png");
  background-size: cover;
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    padding: 2rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    padding: 0rem !important;
  }
`;

const CenteredContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  padding: 10px;
  @media only screen and (max-width: 420px) {
    flex-direction: column !important;
    padding: 10px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column !important;
    padding: 10px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    min-height: 100% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    min-height: 100% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    /* min-height: 100% !important; */
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.GRAY};
  max-width: 1200px !important;
  min-width: 1200px !important;
  margin: 0 auto;
  border-radius: 40px;
  padding: 3rem !important;
  min-height: 80vh !important;

  //Here for small screen updates
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    padding: 1rem !important;
    border-radius: 24px;
    max-width: 80% !important;
    min-width: 80% !important;
    margin: 0 !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column !important;
    padding: 1rem !important;
    max-width: 100% !important;
    min-width: 80% !important;
    border-radius: 24px;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    /* padding: 3rem 0 !important; */
    max-width: 100% !important;
    min-width: 80% !important;
    border-radius: 24px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    /* padding: 3rem 0 !important; */
  }
`;

const Content = styled.div`
  /* padding: 20px; */
  width: 40% !important;

  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Header = styled.h1`
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes[48]};
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -0.04em;
  text-align: left;
  color: ${colors.DARK_BROWN};
  margin: 0;
  padding: 0;
  width: 50%;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
    padding-bottom: 1rem !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const Ptag = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 500;
  line-height: 25.6px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${colors.DEEP_BROWN};
  width: 70% !important;
`;
//

const ApplyButton = styled.button`
  background: ${colors.WHITE};
  gap: 10px;
  padding: 1.2rem 3rem;
  border-radius: 10px;
  border: 0px solid ${colors.WHITE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 600;
  cursor: pointer;
`;

const StyledForm = styled.form`
  width: 40% !important;
  @media only screen and (max-width: 420px) {
    width: 100% !important;
    margin-top: 1rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
    margin-top: 1rem !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    width: 50% !important;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 2rem !important;

  @media only screen and (max-width: 420px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    flex-wrap: wrap !important;
  }
`;

const StyledButton = styled.button`
  padding: 1.2rem 3rem;
  background: ${colors.WHITE};
  border-radius: 12px;
  color: ${colors.DARK_GREY};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 600;
  line-height: 16px;
  width: 100% !important;
  border: 0px solid ${colors.ORANGE};
  margin-top: 0rem;
  cursor: pointer;
  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const StyledButton2 = styled.button`
  padding: 1.2rem 3rem;
  background: ${colors.ORANGE};
  background: ${({ isSubmitted }) =>
    isSubmitted ? `${colors.DARK_SHADE_BROWN}` : `${colors.ORANGE}`} !important;
  border-radius: 12px;
  color: ${colors.WHITE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 600;
  line-height: 16px;
  width: 100% !important;
  border: 0px solid ${colors.ORANGE};
  margin-top: 0rem;
  cursor: pointer;

  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }

  &:hover {
    background: ${({ isSubmitted }) =>
      isSubmitted
        ? `${colors.ORANGE_LIGHT}`
        : `${colors.DARK_BROWN}`} !important;
  }
  &:active {
    background: ${({ isSubmitted }) =>
      isSubmitted
        ? `${colors.DARK_BROWN}`
        : `${colors.ORANGE_LIGHT}`} !important;
  }
`;
