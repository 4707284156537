/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
import { colors, fonts, fontSizes } from "../../../util/Style-Guide";

const Indicator = ({ currentStep }) => {
  return (
    <Wrapper>
      {[...Array(7)].map((_, index) => (
        <Circle key={index} active={index < currentStep} />
      ))}
      {/* {[...Array(6)].map((_, index) => (
        <Circle key={index} active={index + 1 === currentStep} />
      ))} */}
    </Wrapper>
  );
};

export default Indicator;   
  
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Circle = styled.div`
padding: 0.3%;
  width: 40px;
  height: 3px;
  border-radius: 20px;
  background-color: ${({ active }) => (active ? colors.ORANGE : colors.DEEP_LINE_GRAY)};
  margin: 1rem 5px;
`;
