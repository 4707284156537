import React from "react";
import { colors, fonts, fontSizes } from "../../util/Style-Guide";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const navigateToPrivacy = () => {
    navigate("/privacy");
  };
  return (
    <Parent>
      <Main>
        <Headercontainer>
          <Header>Join our community to connect with us</Header>
        </Headercontainer>
        <RingParent>
          <div>
            <RingImages1 src="/FooterRing.png" alt="ring" />
          </div>
          <Ringchildren>
            <a
              href="https://x.com/fashionfuturep"
              target="_blank"
              rel="noopener noreferrer"
            >
              <RingImages src="/Social1.svg" alt="social media" />
            </a>
            {/* <RingImages src="/Social2.svg" alt="social media" />
            <RingImages src="/Social3.svg" alt="social media" /> */}
            <a
              href="https://www.linkedin.com/company/ethnocentrique-limited/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <RingImages src="/Social4.svg" alt="social media" />
            </a>
            <a
              href="https://www.youtube.com/@FashionFutureProgram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <RingImages src="/Social5.svg" alt="social media" />
            </a>
            <a
              href="https://www.instagram.com/fashionfutureprogram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <RingImages src="/Social6.svg" alt="social media" />
            </a>
             {/* <a
              href="https://www.tiktok.com/@fashionfutureprogram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <RingImages src="/Social8.svg" alt="social media" />
            </a> */}
          </Ringchildren>
          <div>
            <RingImages1 src="/FooterRing1.png" alt="ring" />
          </div>
        </RingParent>
        <Disclamer>
          <Disclaimerparagraph>
            Disclaimer: This program is 100% free no charges for application,
            please report any one trying to solicit funds to apply for this
            programme on +234 7079305887 | info@fashionfutureprogram.org
          </Disclaimerparagraph>
          <Disclaimerparagraph>
            Copyright @2024 FFP. All rights reserved. ||{" "}
            <ParentSpan onClick={navigateToPrivacy}>
              Click here to read our Privacy Policy
            </ParentSpan>
          </Disclaimerparagraph>
        </Disclamer>
      </Main>
    </Parent>
  );
};

export default Footer;

const ParentSpan = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

const Parent = styled.div`
  background: ${colors.DARK_SHADE_BROWN};
  padding: 5rem 0;
  @media only screen and (max-width: 420px) {
    padding: 2rem 0;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    padding: 2rem 0;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    padding: 2rem 0;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    padding: 2rem 0;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    padding: 3rem 0;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    padding: 3rem 0;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Main = styled.div`
  text-align: center;
  @media only screen and (max-width: 420px) {
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const Headercontainer = styled.div`
  text-align: center;
  margin: 0 auto;
  /* padding: 0 20px; */
  width: 40%;
  @media only screen and (max-width: 420px) {
    width: 100% !important;
    padding: 0 !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
    padding: 0 !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    width: 100% !important;
    padding: 0 !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    width: 100% !important;
    padding: 0 !important;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    width: 60% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    width: 60% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Header = styled.h1`
  color: ${colors.LIGHT_PINK};
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes[48]};
  font-weight: 700;
  line-height: 57.6px;
  letter-spacing: -0.04em;
  padding: 0;
  margin: 0;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.small};
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.medium};
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    font-size: ${fontSizes.large};
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.large};
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const RingParent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Ringchildren = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  @media only screen and (max-width: 420px) {
    gap: 2px;
    margin: 0;
    padding: 0;
  }

  @media only screen and (min-width: 421px) and (max-width: 576px) {
    gap: 10px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    gap: 10px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    gap: 10px;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    gap: 10px;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    gap: 15px;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const RingImages1 = styled.img`
  width: 150px;
  height: 150px;
  @media only screen and (max-width: 420px) {
    width: 30px;
    height: 30px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 40px;
    height: 40px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    width: 50px;
    height: 50px;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    width: 60px;
    height: 60px;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    width: 60px;
    height: 60px;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const RingImages = styled.img`
  @media only screen and (max-width: 420px) {
    width: 20px;
    height: 20px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 30px;
    height: 30px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    width: 40px;
    height: 40px;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    width: 50px;
    height: 50px;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    width: 50px;
    height: 50px;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Disclamer = styled.div`
  width: 60%;
  margin: 0 auto;
  padding: 0 20px;
  @media only screen and (max-width: 420px) {
    margin-top: 1rem;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    margin-top: 1rem;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    margin-top: 1rem;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    margin-top: 1rem;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    margin-top: 1rem;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    margin-top: 1rem;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Disclaimerparagraph = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 500;
  line-height: 25.6px;
  letter-spacing: -0.02em;
  text-align: center;
  color: ${colors.LIGHT_PINK};
  padding: 0;
  margin: 0;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraSmall};
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraSmall};
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
