import React, { useState } from "react";
import Indicator from "../StepIndicator/Indicator";
import { colors, fonts, fontSizes } from "../../../util/Style-Guide";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const options = [
  { value: "facebook", label: "Facebook" },
  { value: "instagram", label: "Instagram" },
  { value: "linkedin", label: "LinkedIn" },
  { value: "twitter", label: "Twitter" },
  { value: "tiktok", label: "Tiktok" },
  { value: "youtube_ads", label: "YouTube Ads" },
  { value: "whatsapp", label: "Whatsapp" },
  { value: "telegram", label: "Telegram" },
  { value: "ffp-website", label: "FFP Webiste" },
  { value: "ethnocentrique-website", label: "Ethnocentrique Webiste" },
  { value: "a_friend", label: "A friend" },
  { value: "intake_source", label: "Intake Source" },
  { value: "radio", label: "Radio" },
  { value: "billboard", label: "Bill Boards" },
  { value: "newspaper", label: "Newspaper" },
  { value: "others", label: "Others" },
];

const Page2 = ({ nextStep, prevStep, formData, setFormData }) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const navigateToHome = () => {
    navigate("/");
  };

  const handleSelectChange = (selectedOptions) => {
    setFormData({
      ...formData,
      heardAboutUs: selectedOptions.map((option) => option.value),
    });
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "8px",
      border: `1px solid ${colors.LIGHT_GRAY}`,
      background: `${colors.DEEP_GRAY}`,
      color: `${colors.DEEPER_GRAY}`,
      fontFamily: `${fonts.FONT_FAMILY_BODY}`,
      fontSize: `${fontSizes.extraSmall}`,
      fontStyle: "normal",
      fontWeight: 400,
      marginTop: "0.5rem",
      width: "100%",
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
      maxWidth: "95%",
      overflowX: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px",
      overflowY: "auto",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? `${colors.PRIMARY}`
        : `${colors.DEEP_GRAY}`,
      color: state.isSelected ? `${colors.WHITE}` : `${colors.DEEPER_GRAY}`,
      padding: "2px 2px 2px 12px",
      fontFamily: `${fonts.FONT_FAMILY_BODY}`,
      fontSize: `${fontSizes.extraSmall}`,
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#0056b3",
        color: `${colors.WHITE}`,
      },
    }),
    multiValue: (provided) => ({
      ...provided,
    }),
    multiValueLabel: (provided) => ({
      ...provided,
    }),
    multiValueRemove: (provided) => ({
      ...provided,
    }),
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.availability)
      newErrors.availability = "Availability is required";

    if (!formData.areaOfInterest)
      newErrors.areaOfInterest = "Area of Interest is required";

    if (formData.areaOfInterest === "leatherWork" && !formData.ariaraTraining)
      newErrors.ariaraTraining = "Please specify the area of training";

    if (
      formData.areaOfInterest === "garmentMaking" &&
      !formData.garmentInterest
    )
      newErrors.garmentInterest = "Please specify the area of training";

    if (!formData.tue_sat) newErrors.tue_sat = "This is required";

    if (!formData.levelofExperience)
      newErrors.levelofExperience = "This is required";

    if ((formData.heardAboutUs || []).length === 0)
      newErrors.heardAboutUs = "How did you hear about us? is required";


    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextStep = () => {
    if (validateForm()) {
      nextStep();
    }
  };

  return (
    <FullScreenBackground>
      <CenteredContentWrapper>
        <ContentWrapper>
          <Content>
            <Header>Program Interest</Header>
            <Indicator currentStep={5} totalSteps={6} />
            <Ptag>
              Let’s get to know you enter your accurate Background & Experience
              information for the program.
            </Ptag>
            <ButtonWrapper>
              <ApplyButton onClick={navigateToHome}>Back to home</ApplyButton>
            </ButtonWrapper>
          </Content>

          <StyledForm>
            <StyledChild>
              <StyledLabel htmlFor="availability">
                Are you fully available for a 5-month skills training program?
              </StyledLabel>
              <StyledSelect
                id="availability" 
                name="availability"
                defaultValue={formData.availability}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    availability: e.target.value,
                  })
                }
                required
              >
                <option value="">Select an option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="PartTime">Available Part-Time</option>
              </StyledSelect>
              {errors.availability && (
                <ErrorMessage>{errors.availability}</ErrorMessage>
              )}
            </StyledChild>

            <StyledChild>
              <StyledLabel htmlFor="areaOfInterest">
                Pick an area of interest?
              </StyledLabel>
              <StyledSelect
                id="areaOfInterest"
                name="areaOfInterest"
                defaultValue={formData.areaOfInterest}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    areaOfInterest: e.target.value,
                  })
                }
                required
              >
                <option value="">Select an option</option>
                <option value="garmentMaking">
                  Garment making & Tailoring
                </option>
                <option value="leatherWork">Leather work</option>
              </StyledSelect>
              {errors.areaOfInterest && (
                <ErrorMessage>{errors.areaOfInterest}</ErrorMessage>
              )}
            </StyledChild>

            {formData.areaOfInterest === "leatherWork" && (
              <StyledChild>
                <StyledLabel htmlFor="ariaraTraining">
                  Do you commit that you will be available to learn at Ariaria
                </StyledLabel>
                <StyledSelect
                  id="ariaraTraining"
                  name="ariaraTraining"
                  defaultValue={formData.ariaraTraining}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      ariaraTraining: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select an option</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </StyledSelect>
                {errors.ariaraTraining && (
                  <ErrorMessage>{errors.ariaraTraining}</ErrorMessage>
                )}
              </StyledChild>
            )}

            {formData.areaOfInterest === "garmentMaking" && (
              <StyledChild>
                <StyledLabel htmlFor="garmentInterest">
                  Which type of garment making or tailoring are you interested
                </StyledLabel>
                <StyledSelect
                  id="garmentInterest"
                  name="garmentInterest"
                  defaultValue={formData.garmentInterest}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      garmentInterest: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select an option</option>
                  <option value="male_wears">Male wears</option>
                  <option value="female_wears">Female wears</option>
                </StyledSelect>
                {errors.garmentInterest && (
                  <ErrorMessage>{errors.garmentInterest}</ErrorMessage>
                )}
              </StyledChild>
            )}

            <StyledChild>
              <StyledLabel htmlFor="tue_sat">
                Are you available to partcipate in the program from
                Tuesday-Saturday
              </StyledLabel>
              <StyledSelect
                id="tue_sat"
                name="tue_sat"
                defaultValue={formData.tue_sat}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    tue_sat: e.target.value,
                  })
                }
                required
              >
                <option value="">Select an option</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </StyledSelect>
              {errors.tue_sat && <ErrorMessage>{errors.tue_sat}</ErrorMessage>}
            </StyledChild>

            <StyledChild>
              <StyledLabel htmlFor="levelofExperience">
                What is your level of experience in this area?
              </StyledLabel>
              <StyledSelect
                id="levelofExperience"
                name="levelofExperience"
                defaultValue={formData.levelofExperience}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    levelofExperience: e.target.value,
                  })
                }
                required
              >
                <option value="">Select an option</option>
                <option value="none">None</option>
                <option value="1_3_months">1-3months</option>
                <option value="4_6_months">4-6months</option>
                <option value="7_10_months">7-10months</option>
                <option value="1_2_years">1-2years</option>
                <option value="2_3_years">2-3years</option>
              </StyledSelect>
              {errors.levelofExperience && (
                <ErrorMessage>{errors.levelofExperience}</ErrorMessage>
              )}
            </StyledChild>
            <StyledChild>
              <StyledLabel htmlFor="programmePlatform">
                How did you hear about this Program ?
              </StyledLabel>
              <Select
                isMulti
                name="oftenUsedApp"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                value={options.filter((option) =>
                  (formData.heardAboutUs || []).includes(option.value)
                )}
                onChange={handleSelectChange}
                placeholder="Choose all that apply"
                styles={customStyles}
              />
              {errors.heardAboutUs && (
                <ErrorMessage>{errors.heardAboutUs}</ErrorMessage>
              )}
            </StyledChild>

            <ButtonWrapper>
              <StyledButton onClick={prevStep}> Previous</StyledButton>
              <StyledButton2 onClick={handleNextStep}> Next</StyledButton2>
            </ButtonWrapper>
          </StyledForm>
        </ContentWrapper>
      </CenteredContentWrapper>
    </FullScreenBackground>
  );
};

export default Page2;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 0.5rem;
  font-size: ${fontSizes.small};
`;

const FullScreenBackground = styled.div`
  background-image: url("/Formbackground.png");
  background-size: cover;
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    padding: 2rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    padding: 0rem !important;
  }
`;

const CenteredContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  padding: 10px;
  @media only screen and (max-width: 420px) {
    flex-direction: column !important;
    padding: 10px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column !important;
    padding: 10px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    min-height: 100% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    min-height: 100% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    /* min-height: 100% !important; */
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.GRAY};
  max-width: 1200px !important;
  min-width: 1200px !important;
  margin: 0 auto;
  border-radius: 40px;
  padding: 3rem !important;
  min-height: 80vh !important;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    padding: 1rem !important;
    border-radius: 24px;
    max-width: 80% !important;
    min-width: 80% !important;
    margin: 0 !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column !important;
    padding: 1rem !important;
    max-width: 100% !important;
    min-width: 80% !important;
    border-radius: 24px;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    /* padding: 3rem 0 !important; */
    max-width: 100% !important;
    min-width: 80% !important;
    border-radius: 24px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    /* padding: 3rem 0 !important; */
  }
`;

const Content = styled.div`
  /* padding: 20px; */
  width: 40% !important;

  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Header = styled.h1`
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes[48]};
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -0.04em;
  text-align: left;
  color: ${colors.DARK_BROWN};
  margin: 0;
  padding: 0;
  width: 50%;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
    padding-bottom: 1rem !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const Ptag = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 500;
  line-height: 25.6px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${colors.DEEP_BROWN};
  width: 70% !important;
  /* margin: 0;
padding: 0; */
`;

const ApplyButton = styled.button`
  background: ${colors.WHITE};
  gap: 10px;
  padding: 1.2rem 3rem;
  border-radius: 10px;
  border: 0px solid ${colors.WHITE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 600;
  cursor: pointer;
`;

const StyledForm = styled.form`
  width: 40% !important;
  @media only screen and (max-width: 420px) {
    width: 100% !important;
    margin-top: 1rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
    margin-top: 1rem !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    width: 50% !important;
  }
`;

const StyledChild = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  @media only screen and (max-width: 420px) {
    width: 100%;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 80%;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    width: 60%;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    /* width: 100%; */
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    width: 30%;
  }
`;
const StyledChildDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    gap: 0;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
    gap: 0;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    flex-direction: column !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    flex-direction: column !important;
    gap: 0;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const StyledLabel = styled.label`
  color: ${colors.BLACK};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 150% */
`;
const StyledInput = styled.input`
  border-radius: var(--Radius-radius-sm, 8px);
  border: 1px solid ${colors.LIGHT_GRAY};
  background: ${colors.DEEP_GRAY};
  color: ${colors.DEEPER_GRAY};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding: 4px 12px 4px 12px !important;
  margin-top: 0.5rem;

  &:focus {
    outline: ${`1px solid ${colors.PINK}`};
    font-family: ${fonts.FONT_FAMILY};
  }

  @media only screen and (max-width: 420px) {
    height: 3vh !important;
    font-size: 12px !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    height: 5vh !important;
    font-size: 12px !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    height: 5vh;
  }
`;
const StyledSelect = styled.select`
  border-radius: var(--Radius-radius-sm, 8px);
  border: 1px solid ${colors.LIGHT_GRAY};
  background: ${colors.DEEP_GRAY};
  color: ${colors.DEEPER_GRAY};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding: 4px 12px 4px 12px !important;
  margin-top: 0.5rem;
  height: 6vh !important;

  &:focus {
    outline: ${`1px solid ${colors.PINK}`};
    font-family: ${fonts.FONT_FAMILY};
  }

  @media only screen and (max-width: 420px) {
    height: 5vh !important;
    font-size: 12px !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    height: 5vh !important;
    font-size: 12px !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    height: 5vh;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 2rem !important;

  @media only screen and (max-width: 420px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    flex-wrap: wrap !important;
  }
`;

const StyledButton = styled.button`
  padding: 1.2rem 3rem;
  background: ${colors.WHITE};
  border-radius: 12px;
  color: ${colors.DARK_GREY};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 600;
  line-height: 16px;
  width: 100% !important;
  border: 0px solid ${colors.ORANGE};
  margin-top: 0rem;
  cursor: pointer;
  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const StyledButton2 = styled.button`
  padding: 1.2rem 3rem;
  background: ${colors.ORANGE};
  border-radius: 12px;
  color: ${colors.WHITE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 600;
  line-height: 16px;
  width: 100% !important;
  border: 0px solid ${colors.ORANGE};
  margin-top: 0rem;
  cursor: pointer;
  &:hover {
    background: ${colors.DARK_BROWN} !important;
  }
  &:active {
    background: ${colors.ORANGE_LIGHT};
  }

  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
